@value flexRow, flexColumn, flexColumnCenter from '../../../common/styles/Layouts.module.css';
@value text14, text20, textRegular, textBold, textGrey700, lineHeight24, lineHeight25 from '../../../common/styles/Typography.module.css';

.container {
    composes: flexRow;
    justify-content: center;
    width: 100%;
}

.content {
    composes: flexColumn;
    align-items: center;
    margin: 3rem 0;
    max-width: 58rem;
    width: 95%;
}

.emptyList {
    composes: flexColumnCenter;
    padding: 5rem 0;
}

.image img {
    height: 104px;
}

.title {
    composes: text20 textBold textGrey700 lineHeight25;
    margin-top: 1.5rem;
}

.description {
    composes: text14 textRegular textGrey700 lineHeight24;
    text-align: center;
    margin: 0.75rem 0 1.5rem 0;
    max-width: 23rem;
}