@value flexRow, flexColumn, flexColumnCenter, flexColumnStart from '../../../../common/styles/Layouts.module.css';
@value grey350, grey400, grey700, backgroundGrey100, backgroundBlue600 from "../../../../common/styles/Theme.css";
@value text12, text14, text16, text32, textMedium, textSemiBold, textBold, textItalic, textGrey320, textWhite, textUppercase, letterSpacing027, lineHeight18, lineHeight24, lineHeight46 from '../../../../common/styles/Typography.module.css';

.baseWidth {
    max-width: 51rem;
    width: 90%;
}

.container {
    composes: flexColumn;
}

.headerWrapperIDN {
    composes: flexColumnCenter;
    background-image: linear-gradient(132deg, #003E8F 0%, #C93473 0%, #EB8B23 33%, #F4AF32 65%, #FED628 100%);
    background-repeat: no-repeat;
    background-size: 100%;
}

.header {
    composes: flexColumnStart;
    composes: baseWidth;
    padding: 0.25rem 0;
}

.headerTitleIDN {
    composes: text14 textSemiBold textWhite lineHeight18;
}

.contentWrapper {
    composes: flexColumnCenter;
    background-image: linear-gradient(78deg, #C93473 12%, #002F6C 100%);
    padding: 5.5rem 0 4.25rem 0;
    width: 100%;
}

.contentWrapperIDN {
    composes: contentWrapper;
    padding-top: 3.875rem;
}

.content {
    composes: flexColumnStart;
    composes: baseWidth;
}

.titleContainer {
    composes: flexRow;
    align-items: center;
}

.titleContainer:hover .anchor {
    visibility: visible;
}

.title {
    composes: text32 textBold textWhite lineHeight46;
}

.anchor {
    margin-left: 0.75rem;
    visibility: hidden;
}

.favoriteWrapper {
    margin-left: 2rem;
}

.favorite {
    composes: flexRow;
    align-items: center;
    cursor: pointer;
}

.favoriteIcon {
    composes: flexRow;
}

.favoriteLabel {
    composes: text12 textSemiBold textGrey320 textUppercase letterSpacing027;
    margin: 0.3rem 0 0 0.25rem;
}

.address {
    composes: flexColumn;
    composes: text16 textMedium textWhite lineHeight24;
    margin-top: 1.25rem;
}

.address:empty, .actionContainer:empty {
    display: none;
}

.phone {
    composes: text16 textSemiBold textWhite lineHeight24;
    margin-top: 1.25rem;
}

.actionContainer {
    composes: flexRow;
    margin-top: 1rem;
}

.actionContainer > *:not(:last-child) {
    margin-right: 2rem;
}

